import {Card} from "antd";
import Meta from "antd/es/card/Meta";

const Product = (product) => {
  const {title, description, images} = product;
  return (
      <Card
          hoverable
          style={{width: 240}}
          cover={<img alt="product-img" src={images[0]?.large}/>}
      >
        <Meta title={title} description={description}/>
      </Card>)
}

export default Product;