import './App.css';
import {find} from './api';
import {useState} from "react";
import {Button, Flex, Input, Spin} from "antd";
import Queries from "./components/Queries";
import Products from "./components/Products";
import Title from "antd/es/typography/Title";
import _ from "lodash";

const MAX_INTERACTION_COUNT = 3;

function App() {
  const [searchValue, setSearchValue] = useState("")
  const [nextQueries, setNextQueries] = useState([])
  const [possibleSearchQuery, setPossibleSearchQuery] = useState("")
  const [possibleCategories, setPossibleCategories] = useState([])
  const [thingsToAvoid, setThingsToAvoid] = useState([])
  const [thingsToConsider, setThingsToConsider] = useState([])
  const [loading, setLoading] = useState(false)
  const [answers, setAnswers] = useState([])
  const [interactionCount, setInteractionCount] = useState(0)

  // Vectara
  const [searchResults, setSearchResults] = useState([])

  const reset = () => {
    setNextQueries([])
    setPossibleSearchQuery("")
    setPossibleCategories([])
    setThingsToAvoid([])
    setThingsToConsider([])
    setSearchValue("")
    setLoading(false)
    setAnswers([])
    setInteractionCount(0)
    setSearchResults([])
  }

  const performSearch = () => {
    setLoading(true)
    let updatedAnswers = [...answers];

    if (searchValue) {
      const answer = {
        role: "user",
        content: searchValue
      }
      updatedAnswers.push(answer)
      setAnswers(updatedAnswers)
    }

    find(updatedAnswers)
    .then(result => {
      const {
        response, positiveResults, negativeResults
      } = result;

      const searchResults = _.differenceBy(positiveResults, negativeResults, 'document_id')
      console.log(searchResults)

      if (interactionCount >= MAX_INTERACTION_COUNT) {
        setNextQueries([])
      } else {
        setNextQueries(response.next_queries)
      }

      setThingsToAvoid(response.things_to_avoid)
      setThingsToConsider(response.things_to_consider)
      setInteractionCount(interactionCount + 1)
      setPossibleSearchQuery(response.possible_search_query)
      setPossibleCategories(response.possible_categories)
      setSearchValue("")
      setSearchResults(searchResults)
      setLoading(false)
    })
    .catch(console.error);
  }

  const onUserAnswer = (userAnswer) => {
    console.log(userAnswer);
    const updatedAnswers = [...answers]
    // remove the previous user answer
    const index = updatedAnswers.findIndex(
        answer => answer.role === "assistant" && answer.content
            === userAnswer[0].content)
    if (index > -1) {
      updatedAnswers.splice(index + 1, 1)
      updatedAnswers.splice(index, 1)
    }

    // add the new user answer
    updatedAnswers.push(...userAnswer)
    setAnswers(updatedAnswers)
    console.log(updatedAnswers)
  };

  const searchInProgress = nextQueries.length > 0 || possibleSearchQuery.length
      === 0;
  return (
      <div className="App">
        <Flex vertical gap="small" style={{padding: '20%'}}>

          {searchInProgress && <h1>Let's find what you're looking for!</h1>}

          <Flex gap="large" vertical>
            <Flex vertical gap="small">
              <Queries nextQueries={nextQueries} onAnswer={onUserAnswer}/>
            </Flex>

            {searchInProgress &&
                <Input value={searchValue} size="large" disabled={loading}
                       onPressEnter={performSearch}
                       onChange={(e) => setSearchValue(e.target.value)}
                       placeholder={nextQueries.length > 0
                           ? "Any additional comments?"
                           : "What are you looking for?"}/>
            }

            {nextQueries.length === 0 && possibleSearchQuery
                && <Flex vertical gap="small">
                  <h2>Search Query</h2>
                  <p>{possibleSearchQuery}</p>
                  <h3>Search Categories</h3>
                  <p>{possibleCategories.join(", ")}</p>
                  <h3>Things to Avoid</h3>
                  <p>{thingsToAvoid}</p>
                  <h3>Things to Consider</h3>
                  <p>{thingsToConsider}</p>
                </Flex>
            }

            <Flex gap="small">
              {searchInProgress &&
                  <Button type="primary" disabled={loading}
                          onClick={() => performSearch()}>Submit</Button>}
              <Button type="default" disabled={loading} onClick={reset}>
                Reset
              </Button>
            </Flex>
            <Spin spinning={loading}/>
          </Flex>

          <Title level={2}>Search Results</Title>
          <Flex wrap gap="large" align="center" justify="center">
            <Products results={searchResults}/>
          </Flex>
        </Flex>
      </div>
  );
}

export default App;
