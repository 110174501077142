import MultipleChoice from "../answers/MultipleChoice";
import {Flex} from "antd";
import SingleChoice from "../answers/SingleChoice";
import FreeForm from "../answers/FreeForm";

const Query = (query) => {
  const {text, possible_answers, choice_type, onChange} = query;
  let choiceComponent = null;

  switch (choice_type) {
    case "multiple_choice":
      choiceComponent = <MultipleChoice possible_answers={possible_answers}
                                        onChange={(e) => {
                                          onChange(e.join(","));
                                        }}/>;
      break;
    case "single_choice":
      choiceComponent = <SingleChoice possible_answers={possible_answers}
                                      onSelect={(e) => {
                                        onChange(e.target.value);
                                      }}/>;
      break;
    case "free_form":
      choiceComponent = <FreeForm onChange={(e) => onChange(e.target.value)}/>;
      break;
    default:
      choiceComponent = null;
  }

  return (
      <Flex vertical gap="small">
        <h2>{text}</h2>
        {choiceComponent}
      </Flex>
  )
}

export default Query;

