import {Checkbox} from "antd";

const MultipleChoice = ({possible_answers, onChange}) => {
  const options = possible_answers.map(answer => {
    return {label: answer, value: answer}
  });

  return (
      <Checkbox.Group options={options} onChange={onChange}/>
  )
}

export default MultipleChoice;