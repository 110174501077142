import {Radio} from "antd";

const SingleChoice = ({possible_answers, onSelect}) => {
  const options = possible_answers.map(answer => {
    return {label: answer, value: answer}
  });
  return (
      <Radio.Group options={options} onChange={onSelect}/>
  );
}

export default SingleChoice;