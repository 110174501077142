import Product from "../Product";

const Products = ({results}) => {

  const products = results.map(result => {
    const {document_metadata} = result;
    return {
      asin: result.document_id,
      title: document_metadata.title,

      // "{'avatar': 'https://m.media-amazon.com/images/I/31rBoNEHiFL._SY600_.jpg', 'name': 'Marcus Luttrell', 'about': ['Petty Officer First Class Marcus Luttrell was born in Huntsville, Texas in 1975.']}"
      author: document_metadata.author,
      average_rating: +document_metadata.average_rating,
      categories: document_metadata.categories,
      description: document_metadata.description,

      // "[{'large': 'https://m.media-amazon.com/images/I/41YQHDWRyGL._SX321_BO1,204,203,200_.jpg', 'variant': 'MAIN'}]"
      images: document_metadata.images,
    };
  });

  return products.map(product => <Product key={product.asin} {...product} />);
}

export default Products;