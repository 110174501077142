import {Flex} from "antd";
import Query from "../Query";

const Queries = ({nextQueries, onAnswer, noOfQueries = 3}) => {
  if (!nextQueries || nextQueries.length === 0) {
    return null;
  }

  const onChoiceChange = (queryText, answerText) => {
    const answer = [
      {
        role: "assistant",
        content: queryText
      },
      {
        role: "user",
        content: answerText
      }
    ]
    onAnswer && onAnswer(answer);
  }

  return (
      <Flex vertical gap="small">
        {/*<h2>Refine Search</h2>*/}
        <Flex gap="large">
          {nextQueries.slice(0, noOfQueries).map((query) => {
            return (
                <Query key={query.text} {...query}
                       onChange={(answerText) => onChoiceChange(query.text,
                           answerText)}/>
            )
          })}
        </Flex>
      </Flex>
  )
};

export default Queries;
